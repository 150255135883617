/*
Highlight color ideas
-Keysight uses red and it also matches my school
-Apple has an off-blue
*/
$keysight-highlight: #FF0000;
$apple-highlight: #0066CC;
$magenta-highlight: #FF00FF;

$highlight-color: $apple-highlight;
// $sidebar-background: #A0E0FF;
$sidebar-background: #FFDC8D;

$sidebar-text-height: 120%;
$sidebar-text-size: 0.9em;

$experience-list-text-height: 120%;
$experience-list-text-size: 0.9em;

.resume-page {
  position: relative;
  max-width: 740px;
  margin-right: auto;
  margin-left: auto;

  // .dox-hide {
    // display: none;
  // }
  // .web-dev-hide {
    // display: none;
  // }

  .sidebar {
    float: right;
    width: 300px;
    margin: 15px;
    background-color: $sidebar-background;

    .headshot-image-container {
      text-align: center;

      .headshot-image {
        width: 200px;
        padding-top: 20px;
      }
    }

    .summary-section {
      margin: 10px;

      .summary-section-header {
        font-weight: bolder;
        // font-size: larger;
      }
      .summary-text {
        line-height: $sidebar-text-height;
        margin-bottom: 5px;
        font-size: $sidebar-text-size;
      }
      .summary-text.education {
        font-weight: 500;
      }
      .summary-skills-list {
        padding-left: 15px;
        padding-right: 15px;
        text-align: center;
        line-height: $sidebar-text-height;
        font-size: $sidebar-text-size;
        border-left-width: 8px;
        border-left-style: solid;
        border-right-width: 8px;
        border-right-style: solid;
        border-top-width: 2px;
        border-top-style: dotted;
        border-bottom-width: 2px;
        border-bottom-style: dotted;
        border-color: $highlight-color;
      }
    }
    .summary-text.education {
      font-size: $sidebar-text-size;
    }
    .school-illinois {
      line-height: 80%;
      color: #D13E19;
      margin-bottom: 5px;
    }
    .school-ohiostate {
      line-height: 80%;
      color: #FF0000;
    }
  }

  .header-no-sidebar {
    text-align: center;
  }
  .headline-name {
    font-weight: bolder;
    font-size: 30px;
    margin-bottom: -10px;
  }
  .headline-role {
    font-weight: bolder;
  }

  .headline-contact {
    font-size: small;
    border-top: 2px solid $highlight-color;
    border-bottom: 2px solid $highlight-color;
    margin-top: 3px;
    padding-top: 3px;
    padding-bottom: 3px;
    margin-right: 20px;

    .circle-separator {
      margin-left: 15px;
      margin-right: 15px;
      color: $highlight-color;
    }
    .contact-link {
      white-space: nowrap;
    }
  }
  .experience-header {
    text-align: center;
    font-weight: 500;
    font-size: large;
    margin-top: 10px;
    margin-bottom: 7px;
    
    span.header-words {
      padding-left: 20px;
      padding-right: 20px;
      border-top: 2px solid $highlight-color;
      border-bottom: 2px solid $highlight-color;
    }
  }

  .experience-list {
    line-height: 120%;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 40px;

    .experience-entry {
      .experience-org {
        font-size: larger;
        font-weight: bold;
        line-height: 1em;
      }
      .experience-location {
        width: 200px;
        display: inline-block;
      }
      .experience-dates {
        display: inline;
      }
      ul.experience-accomplishments {
        margin-left: -10px;
        font-size: $experience-list-text-size;
        line-height: $experience-list-text-height;
        // font-weight: 500;
      }
    }
  }
}
