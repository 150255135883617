.about-page {
  .home {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  .header-name {
    font-family: 'Garamond', Garamond, serif;
    font-size: 2rem;
  }

  .headshot-jumbotron {
    width: 100%;
    position: relative;
  }
  .valley-div {
    position: absolute;
    width: 100%;
    text-align: center;
  }
  .valley-div .valley-image {
    max-width: 100%;
    height: 350px;
  }
  .headshot-div {
    position: absolute;
    top: 20px;
    left: 0px;
    right: 0 px;
    width: 100%;
    text-align: center;
  }
  .headshot-div .headshot-image {
    height: 250px;
  }
  .headshot-div .headshot-text-container {
    display: inline-block;
    background-color: skyblue;
    opacity: 80%;
    padding: 10px;
    font-weight: bold;
    font-size: larger;
    border-width: 10px;
    border-style: solid;
    border-color: white;
    border-radius: 40px;
  }

  .summary-container {
    position: relative;
    top: 360px;
    padding-bottom: 30px;
  }
  .summary-container ul {
    padding-top: 20px;
  }
}
